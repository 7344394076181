import React, { useEffect, useState } from 'react'
import banner from '../../Images/banner.png'
import Slider from 'react-slick';
import banner1 from '../../Images/banner.png'
import banner2 from '../../Images/Untitled design (22).png';
import banner3 from '../../Images/black1.png';
import { Link } from 'react-router-dom';
import SanityClient from '../Sanity client';

const Banner = () => {
const[loader,setloader]=useState(true)
  const [datas,setdatas]=useState([])
  useEffect(() =>{
    const fetch = async()=>{
await SanityClient.fetch(`*[_type == "slider"]{
  bannar[]{
    
slidercontent,

sliderimage{
  asset->{
    url
  }
},
slidertitle

  }
}
`).then((result)=>{
  setdatas(result[0])
  console.log(result[0],'banner');
})
    }
fetch()
  }
,[])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false
  };

  const [ active, setActive ] = useState('');

  const data = [["Drive Your Growth", "With Innovative and", "Diverse Capital Solutions"],
    ["Tailored Strategies, Tangible Growth:", "Experience Our Expertise", "To Elevate Our Growth"], 
    ["Innovative Partnerships:", "Join us to Drive", "Sustainable Growth"]]

  useEffect(()=> {
    const time = setInterval(()=> {
        setActive((prev)=> (prev+data.length+1)%data.length)
    }, 2000);

    // console.log(active)
    return ()=> clearInterval(time)
  }, [data])

  return (
    <>
    {
      datas && datas.bannar ?  <section className="banner-section w-full h-full relative">
      <Slider {...settings} className='w-full h-full'>
        {datas && datas.bannar&&datas.bannar.map((item,index)=>(<div className="relative w-full h-[300px] md:h-[500px]">
          <img src={item && item.sliderimage &&item?.sliderimage?.asset?.url} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2 flex flex-col justify-center items-center px-6 md:px-20 text-white">
            <h1 className='font-bold text-lg xs:text-3xl text-center md:w-[70%] w-full md:text-6xl'>{item.slidertitle}</h1>
            <h2 className=' text-sm xs:text-base md:text-xl text-center'>{item.slidercontent}</h2>
            <Link to="/contact" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Enquire Now
              </button>
            </Link>
          </div>
        </div>
))}
        
        {/* <div className="relative w-full h-[300px] md:h-[500px]">
          <img src={banner2} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2  flex flex-col justify-center items-center px-6 md:px-20 text-white">
            <div>
              <h1 className='font-bold text-lg xs:text-3xl md:text-6xl'>Tailored Strategies,</h1>
              <h1 className='font-bold text-lg xs:text-3xl md:text-6xl text-center'>Tangible Growth</h1>
            </div>
            <h2 className='text-sm xs:text-base md:text-xl text-center'>Experience our expertise to elevate your business</h2>
            <Link to="/contact" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Enquire Now
              </button>
            </Link>
          </div>
        </div>

        <div className="relative w-full h-[300px] md:h-[500px]">
          <img src={banner3} alt="Banner 3" className="object-cover w-full h-full" />
          <div className="absolute inset-0 gap-2  flex flex-col justify-center items-center px-6 md:px-20 text-white">
            <h1 className='font-bold text-lg xs:text-3xl md:text-6xl'>Innovative Partnerships</h1>
            <h2 className='text-sm xs:text-base md:text-xl text-center'>Join us to drive sustainable growth</h2>
            <Link to="/contact" className="inline-block font-inter mt-4">
              <button className="bg-[#fd5d14] hover:bg-white hover:text-black text-sm sm:text-md text-white font-bold md:py-3 md:px-5 py-2 px-2 rounded">
                Enquire Now
              </button>
            </Link>
          </div>
        </div> */}
      </Slider>
    </section>:<div class="flex flex-row gap-2 h-screen justify-center items-center">
  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.3s]"></div>
  <div class="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
</div>
    }
   
  
    </>
  )
}

export default Banner
