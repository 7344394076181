import React, { useState } from "react";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Images/logo3.png";

const Mheader = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleSubMenu = (index) => {
    setActiveSubMenu(index === activeSubMenu ? null : index);
  };

  // Function to check if the current route matches the link
  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  return (
    <div className="shadow-md block sm:hidden z-20 w-full font-inter top-0 left-0 sticky text-black">
      <div className="flex justify-between items-center bg-white py-2 px-2 relative">
        <div className="font-bold text-md text-black cursor-pointer w-fit">
        <Link to='/'>
                <img src={logo} alt='logo' className='h-12  p-2  cursor-pointer' />
            </Link>
        </div>
        <div onClick={toggleMenu} className="cursor-pointer sm:hidden w-7" >
          {open ? <FaTimes /> : <FaBars />}
        </div>
        <div className={`absolute top-[3.55rem] w-full grid capitalize active:text-orange-500 bg-white py-2 ${open ? 'block' : 'hidden'}`}>
          <div>
            <Link to="/" className={`py-1 ${isActive('/') ? '' : ''}`} onClick={toggleMenu}>
              <p className="text-md pl-6  pt-4">home</p>
            </Link>
            <Link
              to="/about"
              className={`py-1 ${isActive('/AboutUs') ? 'text-[#fd5d14]' : ''}`}
              onClick={toggleMenu}
            >
              <p className="text-md pl-6 pt-4">about us</p>
            </Link>

            <div className="py-2">
              <div onClick={handleMenu} className="flex items-center  pt-4  gap-1">
                <p className="text-md pl-6 flex item-cemter">services</p>
                <FaChevronDown className={`${menu ? 'block' : 'hidden'}`} />
                <FaChevronDown className={`${menu ? 'hidden' : 'block'}`} />
              </div>

              <div className={`${menu ? 'block' : 'hidden'}`}>
                <Link to="/Services/capitaladvisory" className="flex items-center">
                  <p className=" text-sm  flex items-center pl-6 pt-4"onClick={toggleMenu}>capital advisory</p>
                  <div className="ml-1 mt-4  flex items-center" onClick={() => handleSubMenu(1)}>
                    <FaChevronDown className={`${activeSubMenu === 1 ? 'hidden' : 'block'}`} />
                    <FaChevronDown className={`${activeSubMenu === 1 ? 'block' : 'hidden'}`} />
                  </div>
                </Link>

                <div className={`${activeSubMenu === 1 ? 'block' : 'hidden'}`}onClick={toggleMenu}>
                  <Link to="/Services/capitaladvisorydivision/Private Equity" className="text-sm  flex pl-6 pt-4">Private Equity</Link>
                  <Link to="/Services/capitaladvisorydivision/Private Debt" className="text-sm  flex pl-6 pt-4">Private Debt</Link>
                  <Link to="/Services/capitaladvisorydivision/Venture Capital" className="text-sm  flex pl-6 pt-4">Venture Capital</Link>
                  <Link to="/Services/capitaladvisorydivision/Business Finance" className="text-sm   flex pl-6 pt-4">Business Finance</Link>
                  <Link to="/Services/capitaladvisorydivision/Real Estate Finance" className="text-sm  flex pl-6 pt-4">Real Estate Finance</Link>
                  <Link to="/Services/capitaladvisorydivision/Project Finance" className="text-sm   flex pl-6 pt-4">Project Finance</Link>
                </div>
              </div>

              <div className={`${menu ? 'block' : 'hidden'}`}>
                <Link to="" className="flex items-center">
                  <p className=" text-sm  flex items-center pl-6 pt-4"onClick={toggleMenu}>Mergers and Acquisitions</p>
                  {/* <div className="ml-1 mt-4  flex items-center" onClick={() => handleSubMenu(2)}>
                    <FaChevronDown className={`${activeSubMenu === 2 ? 'hidden' : 'block'}`} />
                    <FaChevronDown className={`${activeSubMenu === 2 ? 'block' : 'hidden'}`} />
                  </div> */}
                </Link>

                {/* <div className={`${activeSubMenu === 2 ? 'block' : 'hidden'}`}onClick={toggleMenu}>
                  <Link to="/Services/Consultancy Services/Financial Planning and Analysis" className="text-sm flex pl-6  pt-4"> Financial Planning and Analysis</Link>
                  <Link to="/Services/Consultancy Services/Interim CFO Services" className="text-sm flex pl-6  pt-4">Interim CFO Services</Link>
                  <Link to="/Services/Consultancy Services/Financial Modelling and Valuation" className="text-sm  flex pl-6 pt-4">Financial Modelling and Valuation</Link>
                  <Link to="/Services/Consultancy Services/CRTM" className="text-sm flex  pl-6 pt-4">Corporate Restructuring and Turnaround Management</Link>
                  <Link to="/Services/Consultancy Services/Business Planning" className="text-sm  flex pl-6 pt-4">Business Planning</Link>
                  <Link to="/Services/Consultancy Services/Feasibility Studies" className="text-sm  flex pl-6 pt-4"> Feasibility Studies</Link>
                  <Link to="/Services/Consultancy Services/Market Research and Analysis" className="text-sm  flex pl-6 pt-4">Market Research and Analysis</Link>
                  <Link to="/Services/Consultancy Services/Digital Transformation" className="text-sm flex  pl-6 pt-4">Digital Transformation</Link>
                </div> */}
              </div>
            


              <div className={`${menu ? 'block' : 'hidden'}`}>
                <Link to="/Services/ConsultancyService" className="flex items-center">
                  <p className=" text-sm  flex items-center pl-6 pt-4"onClick={toggleMenu}>consultancy services</p>
                  <div className="ml-1 mt-4  flex items-center" onClick={() => handleSubMenu(2)}>
                    <FaChevronDown className={`${activeSubMenu === 2 ? 'hidden' : 'block'}`} />
                    <FaChevronDown className={`${activeSubMenu === 2 ? 'block' : 'hidden'}`} />
                  </div>
                </Link>

                <div className={`${activeSubMenu === 2 ? 'block' : 'hidden'}`}onClick={toggleMenu}>
                  <Link to="/Services/Consultdivision/Financial Planning and Analysis" className="text-sm flex pl-6  pt-4"> Financial Planning and Analysis</Link>
                  <Link to="/Services/Consultdivision/Interim CFO Services" className="text-sm flex pl-6  pt-4">Interim CFO Services</Link>
                  <Link to="/Services/Consultdivision/Financial Modelling and Valuation" className="text-sm  flex pl-6 pt-4">Financial Modelling and Valuation</Link>
                  <Link to="/Services/Consultdivision/CRTM" className="text-sm flex  pl-6 pt-4">Corporate Restructuring and Turnaround Management</Link>
                  <Link to="/Services/Consultdivision/Business Planning" className="text-sm  flex pl-6 pt-4">Business Planning</Link>
                  <Link to="/Services/Consultdivision/Feasibility Studies" className="text-sm  flex pl-6 pt-4"> Feasibility Studies</Link>
                  <Link to="/Services/Consultdivision/Market Research and Analysis" className="text-sm  flex pl-6 pt-4">Market Research and Analysis</Link>
                  <Link to="/Services/Consultdivision/Accounting Services" className="text-sm flex  pl-6 pt-4">  Accounting Services</Link>
                  <Link to="/Services/Consultdivision/Tech and Digital Consulting" className="text-sm flex  pl-6 pt-4"> Tech and Digital Consulting</Link>
                </div>
              </div>
            </div>

            <Link to="/blog" className={`py-2 ${isActive('/Blog') ? 'text-[#fd5d14]' : 'text-black'}`} onClick={toggleMenu}>
              <p className="text-md  pl-6 pt-4">blog</p>
            </Link>
            <Link to="/contact" className={`py-2 ${isActive('/ContactUs') ? 'text-[#fd5d14]' : 'text-black'}`} onClick={toggleMenu}>
              <p className="text-md  pl-6 pt-4">contact us</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mheader;
