import React from 'react'
import banner1 from '../../Images/banner1.jpg'
import banner2 from '../../Images/banner2.jpg'
import mission from '../../Images/mission.png'
import vision from '../../Images/vision2.png'
import corevalue from '../../Images/corevalue.png'
import india from '../../Images/Group_41__2_-removebg-preview.png'
import saudi from '../../Images/saudi_arabia-removebg-preview (1).png'
import bahrain from '../../Images/Group_42-removebg-preview.png'
import dubai from '../../Images/burj khalifa.png'
import { useEffect, useState } from 'react'
import Testimonials from '../Home2/Testimonials'
import whatsetsusapart from '../../Images/whatsetsusapart.jpg'
import backgroundImage from '../../Images/blackeagle.png'
import whatsetsusapart2 from '../../Images/whatsetsusapart2.png'
import SanityClient from '../Sanity client'

const AboutUs = () => {
    const [data,setdata]=useState([])
    useEffect(() => {
   const getdata=async()=>{
await SanityClient.fetch(`*[_type=="aboutus"]{
    metatitle,
    focuskey,
    metaurl,
    metadescription,
    aboutarray[]{
        aboutimage{
            asset->{
                url
            }
        },
        heading,
        subheading
    },
    secondarray[]{
        secondimage{
            asset->{
                url
            }
        },
        heading,
        subheading
    },

    thirdarray[]{
        thirdimage{
            asset->{
                url
            }
        },
        heading,
        subheading,
        points[]{
            point
        }
    },
    fourtharray[]{
        Heading,
        subheading,
        imagearray[]{
            image{
                asset->{
                    url
                }
            },place
        }
    },
    fiftharray[]{
        Heading,
        subheading
    }
}`).then((response)=>{
    console.log(response,'abo');
    setdata(response[0])
})
   }
   getdata()
    },[])
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, []);

    return (

    <div className='font-inter gap-5 text-justify'>
        <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-4xl text-white font-bold">About Us</h1>
          </div>
        </div>
      </div>

     
    {/* First section */}
    {data && data.aboutarray&&data.aboutarray.map((item,index)=>(
        <div key={index}>
        { index % 2==0 ?
            <section className='flex flex-col md:flex-row px-[5%] md:pt-16 md:pb-10 md:gap-10 py-8'>
      
    <section className='flex flex-col gap-10 justify-center'>
        <h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>{item && item.heading}</h1>
        <p className=' text-sm xs:text-sm md:text-base text-justify'>{item && item.subheading}</p>
    </section>
    <section className='relative flex justify-center items-center mt-6 md:w-1/2'>
        <img src={item?.aboutimage?.asset?.url} alt='banner1' className=''/>
    </section>
</section>:<section className='flex flex-col-reverse md:flex-row px-[5%] md:py-10 gap-5 py-8'>
    {/* Second section */}
    <section className='relative flex justify-center items-center md:w-1/2'>
        <img src={item?.aboutimage?.asset?.url} alt='banner2' className=''/>
    </section>
    <section className='flex flex-col gap-10 md:w-1/1'>
        <div className='w-full flex md:justify-end justify-start'><h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>{item && item.heading}</h1></div>
        <p className='text-sm xs:text-sm md:text-base text-justify'>{item && item.subheading}</p>
    </section>  
</section>}
        </div>
    ))}
   





   <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full md:p-10 sm:p-5 gap-10 md:py-10 px-5 py-8'>
            {data && data.secondarray&&data.secondarray.map((item,index)=>(
                <div className='w-full flex flex-col'>
                    {index%2==0?<section className='bg-slate-300 w-full h-[350px] md:h[500px]  lg:h-[400px] rounded-md p-5 flex flex-col justify-between'>
                <div className='flex justify-center'>
                    <img src={item?.secondimage?.asset?.url} alt='' className='h-20 xs:h-28 md:h-36'/>
                </div>
                <div className='flex flex-col gap-3'>
                    <h1 className='relative w-fit wesf2 mt-5 font-bold text-lg md:text-2xl'>{item && item.heading}</h1>
                    <p className='md:text-base xs:text-base text-sm'>{item && item.subheading}</p>
                </div>
               </section>:<section className='bg-slate-300 w-full h-[300px] md:h[500px]  lg:h-[400px] rounded-md p-5 flex flex-col-reverse md:flex-col justify-between'>
                <div className='flex flex-col gap-3'>
                    <h1 className='relative w-fit wesf2 mt-5 font-bold text-lg md:text-2xl'>{item && item.heading}</h1>
                    <p className='md:text-base xs:text-base text-sm'>{item && item.subheading}</p>
                </div>
                <div className='flex justify-center'>
                    <img src={item?.secondimage?.asset?.url} alt='' className='h-20 xs:h-28 md:h-36'/>
                </div>
            </section>}
                    </div>
 
            ))}
           
            
        </section>


        <section className='flex flex-col lg:flex-row'>
            {data && data.thirdarray && data.thirdarray.map((item, index)=>(
                <div className='flex flex-col lg:flex-row md:px-16 px-5 py-6 md:py-10'>
 <section className='pb-5 md:p-5 flex flex-col gap-5 md:w-[50%] lg:w-[65%]'>
                <h1 className='font-bold text-base xs:text-xl md:text-2xl wesf relative w-fit'>{item && item.heading}</h1>
                <p className=' text-sm xs:text-sm md:text-base text-justify lh'>{item && item.subheading}</p>
                <ul className='list-disc text-sm pl-5 flex flex-col gap-2  xs:text-sm md:text-base'>
                    {item && item.points&&item.points.map((point,index)=>(
                        <li>{point.point}</li>
                    ))}
                    
               
                </ul>
            </section>
            <section className='flex justify-end items-center md:w-[50%] lg:w-[35%]'>
                <img src={item?.thirdimage?.asset?.url} alt='' className='block md:hidden rounded-md'/>
                <img src={whatsetsusapart2} alt='' className='hidden md:block h-[550px] lg:h-[450px] rounded-md shadow-2xl'/>
            </section>
                </div>
            ))}
           
        </section>
        
<div>
    <Testimonials />
</div>
        <section className='p-5 md:p-10 w-full flex flex-col gap-5 bg-white rounded-2xl md:px-10 text-black'>
            {data && data.fourtharray&&data.fourtharray.map((item,index)=>(
                <div>
 <h1 className='text-center mb-6 text-base xs:text-lg md:text-2xl font-bold'>{item && item.Heading}</h1>
            <p className='text-justify mb-7 text-sm xs:text-base md:text-center md:text-md'>{item && item.subheading}</p>

            <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-items-center'>
{item && item.imagearray&&item.imagearray.map((images,index)=>(
    <section className=''>
                    <img src={images?.image?.asset?.url} alt='' className='size-32 xs:size-60 md:size-40'/>                    
                    <p className='text-center font-bold mt-3 xs:text-lg md:text-xl'>{images && images.place}</p>
                </section>
))}
                
                </section>

                </div>
            ))}
           </section>
                {/* <section>
                    <img src={dubai} alt='' className='size-32 xs:size-60 md:size-40'/>
                    <p className='text-center font-bold mt-3 xs:text-lg md:text-xl'>Dubai</p>
                </section>

                <section>
                    <img src={saudi} alt='' className='size-32 xs:size-60 md:size-40'/>
                    <p className='text-center font-bold mt-3 xs:text-lg md:text-xl'>Saudi Arabia</p>
                </section>

                <section>
                    <img src={bahrain} alt='' className='size-32 xs:size-60 md:size-40'/>
                    <p className='text-center font-bold mt-3 xs:text-lg md:text-xl'>Bahrain</p>
                </section>
            </section>
        </section> */}
<div>
    {data && data.fiftharray&&data.fiftharray.map((item,index)=>(
        <div className='bg-[#FFA500]  md:mb-20 p-2 mb-10 '>
            <div className='relative md:px-20 px-4 bg-white text-center rounded-md'>
                <h1 className=' text-center text-base xs:text-lg md:text-2xl font-bold py-10'>{item && item.Heading}</h1>
                <p className='pb-16 text-justify md:text-center'>{item && item.subheading}

</p>

            </div>
        </div>
    ))}


</div>
        


        
    </div>
  )
}

export default AboutUs
