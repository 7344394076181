import React, { useEffect, useState } from "react";
import bg from "../../Images/Group 28 (1).png";
import sectionbg1 from "../../Images/discover1.png";
import sectionbg2 from "../../Images/discover2.png";
import sectionbg3 from "../../Images/discover3.png";
import sectionbg4 from "../../Images/discover4.png";
import { MdOutlineFolderShared } from "react-icons/md";
import { CiVirus } from "react-icons/ci";
import { LiaUsersSolid } from "react-icons/lia";
import { FaUsers } from "react-icons/fa";
import file from "../../Images/file.png";
import SanityClient from "../Sanity client";
const Discover = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const getdata = async () => {
      await SanityClient.fetch(
        `*[_type=="discover"]{
            title,
            arraycontent[]{
                heading,
                content,
                Image{
                    asset->{
                        url
                    }
                },
                icons{
                    asset->{
                        url
                    }
                },



            }
        }`
      ).then((res) => {
        console.log(res[0], "dis");
        setdata(res[0]);
      });
    };
    getdata();
  }, []);
  return (
    <div className="w-full h-fit relative flex flex-col items-center md:p-10 p-5">
      <div className="absolute top-0 -z-10 w-full h-full">
        
        <img src={bg} alt="background" className="object-cover w-full h-full" />
      </div>
      <h1 className="text-lg text-center xs:text-xl md:text-3xl font-bold text-white">
        {data && data.title}
      </h1>
      <section className="mt-[7%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xs:w-full justify-items-center gap-5 pb-20">
        {data &&
          data.arraycontent &&
          data.arraycontent.map((item, index) => (
            <section className="flex flex-col justify-between w-full xs:w-[70%] sm:w-[50%] md:w-[90%] items-center bg-white shadow-2xl rounded-2xl ">
              <section className="w-full relative">
                <img src={item?.icons?.asset?.url} className="absolute  top-[135px] left-[-25px] text-white w-12  bg-primary p-1 text-5xl rounded-full max-sm:hidden " />
                <img
                  src={item?.Image?.asset?.url}
                  alt="bg1"
                  className="w-full object-cover h-40 xs:h-52 md:h-40 rounded-lg"
                />
              </section>
              <section className="flex gap-5 p-5 flex-col  ">
               
                <p className="font-bold text-lg lg:text-md">
                  {item && item.heading}
                </p>
                <p className="text-base xs:text-lg lg:text-sm  text-justify">
                  {item && item.content}
                </p>
              </section>
            </section>
          ))}

        {/* <section className='flex flex-col justify-between w-full xs:w-[70%] sm:w-[50%] md:w-[90%] items-center bg-white shadow-2xl rounded-2xl '>
                <section className='w-full relative'>
                    <img src={sectionbg2} alt='bg1' className='w-full object-cover h-40 xs:h-52 md:h-40 rounded-lg'/>
                    <CiVirus className='absolute top-[135px] left-[-25px] text-white bg-primary p-1 text-5xl rounded-full hidden sm:block' />
                </section>
                <section className='flex gap-5 p-5 flex-col '>
                   
                    <p className='font-bold text-lg lg:text-md'>Team Expertise</p>
                    <p className='text-base xs:text-lg lg:text-sm  text-justify'>Our dedicated team of professionals brings together a wealth of experience and knowledge, ensuring that you receive top-notch guidance and support to achieve your business goals</p>
                </section>
            </section>
            <section className='flex  flex-col justify-between w-full xs:w-[70%] sm:w-[50%] md:w-[90%] items-center bg-white shadow-2xl rounded-2xl '>
                <section className='w-full relative'>
                    <img src={sectionbg3} alt='bg1' className='w-full object-cover h-40 xs:h-52 md:h-40 rounded-lg'/>
                    <LiaUsersSolid className='absolute top-[135px] left-[-25px]  text-white bg-primary p-1 text-5xl rounded-full hidden sm:block' />
                </section>
                <section className='flex gap-5 p-5 flex-col  '>
                    
                    <p className='font-bold text-lg lg:text-md'>Capital Solutions</p>
                    <p className='text-base xs:text-lg  lg:text-sm  text-justify'>Our seamless blend of financial expertise, strategic consultancy, and innovative solutions ensures comprehensive support for your business at every stage of growth and transformation.</p>
                </section>
            </section>
            <section className='flex  flex-col justify-between w-full xs:w-[70%] sm:w-[50%] md:w-[90%] items-center bg-white shadow-2xl rounded-2xl '>
                <section className='w-full relative'>

                    <img src={sectionbg4} alt='bg1' className='w-full object-cover h-40 xs:h-52 md:h-40 rounded-lg'/>
                    <FaUsers className='absolute top-[130px] left-[-25px] text-white bg-primary p-1 text-5xl rounded-full hidden sm:block' />
                </section>
                <section className='flex gap-5 p-5 flex-col '>
                   
                    <p className='font-bold text-lg lg:text-md'>Global Reach</p>
                    <p className='text-base xs:text-lg lg:text-sm text-justify'>From Bahrain to Dubai, Saudi Arabia, and India, our expansive network enables us to deliver impactful solutions wherever your business operates, fostering growth and success on a global scale</p>
                </section>
            </section> */}
      </section>
    </div>
  );
};

export default Discover;
