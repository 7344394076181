import React from 'react'
import Banner from './Banner'
import Cards from './Cards'
import Ourservices from './OurServices'
import AssociateCompanies from './AssociateCompanies'
import IndustryCoverage from './IndustryCoverage'
import Discover from './Discover'

import Testimonials from './Testimonials';
import { useEffect } from 'react'



const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Banner/>
      {/* <Banner /> */}
      <Cards />
      {/* <Consultation /> */}
      <Ourservices />
      <AssociateCompanies />
      <IndustryCoverage />
      <Discover />
      <Testimonials />
      
       
    </div>
  )
}

export default Home
