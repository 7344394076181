import React, { useState } from 'react';
import backgroundImage from '../../Images/blackeagle.png';
import { MdLocalLibrary } from "react-icons/md";
import Approach from '../Services/Approach';
import { RiShieldUserFill } from "react-icons/ri";
import Card3 from '../../Images/black3 (2).png';
import Card1 from '../../Images/image-2-1.png';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const FaqItem = ({ question, answer }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleAnswer = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="border-b border-gray-200 font-inter py-4">
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">{question}</div>
          <button onClick={toggleAnswer} className="focus:outline-none">
            {isOpen ? '-' : '+'}
          </button>
        </div>
        {isOpen && <div className="text-gray-600 mt-2">{answer}</div>}
      </div>
    );
  };

function Services2() {
  return (
    <div className='w-full font-inter'>
        <div className='bg-black'>
            <div className="relative">
                <img src={backgroundImage} className="w-full h-40 md:h-full  object-cover opacity-40" alt="Background" />
                <div className="absolute inset-0 flex justify-center items-center">
                    <h1 className="md:text-4xl text-xl text-center text-white font-bold">CONSULTANCY SERVICES</h1>
                </div>
            </div>
        </div>

        <div className='bg-white p-2 flex  justify-center items-center flex-col '>
            <h4 className='text-lg md:text-2xl font-bold text-black mt-4 mb-0'>CONSULTANCY SERVICES</h4>
            <p className="text-[14px] text-justify md:text-center text-black p-4">Leverage our expertise and insights to transform your business and achieve sustainable growth.
            </p>
        </div>

        <div className='flex flex-col gap-5'>
            <div className="grid grid-cols-2 w-full md:justify-center md:items-center md:gap-10 gap-5 md:px-20 px-4  md:mt-4 ">
               {/* Card 1 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full  transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to="/Services/Consultancy Services/Financial Planning and Analysis">
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Financial Planning and Analysis</h2>
                            <p className="text-sm md:text-base text-justify">Craft robust financial plans and analyses to drive informed decision-making and strategic growth. Empower your business with data-driven insights and strategic financial planning.        </p>
                        </div>
                    </div>
                    </Link>
                </div>
                {/* Card 2 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                    <Link to='/Services/Consultancy Services/Interim CFO Services'>
                    <div className=" p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Interim CFO Services</h2>
                            <p className="text-sm md:text-base text-justify">Access seasoned CFO expertise to guide your business through periods of transition and growth. Navigate transitions and accelerate growth with our interim CFO services.</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:justify-center md:items-center md:gap-10 gap-5 md:px-20 px-4  md:mt-4 ">
                {/* Card 1 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/Financial Modelling and Valuation'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Financial Modelling and Valuation</h2>
                            <p className="text-sm md:text-base text-justify">Harness the power of advanced financial modeling and valuation techniques to maximize value and minimize risk. Unlock insights and make informed decisions with our sophisticated financial modeling and valuation expertise.</p>
                        </div>
                    </div>
                    </Link>
                </div>
                {/* Card 2 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/CRTM'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Corporate Restructuring and Turnaround Management</h2>
                            <p className="text-sm md:text-base text-justify">Navigate challenging times with confidence through strategic corporate restructuring and turnaround management. Transform your business and drive turnaround success with our expert guidance .</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:justify-center md:items-center md:gap-10 gap-5 md:px-20 px-4  md:mt-4 ">
                {/* Card 1 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/Business Planning'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Business Planning</h2>
                            <p className="text-sm md:text-base text-justify">Develop strategic blueprints for success with our comprehensive business planning services. Chart a clear path to success with our strategic business planning expertise.</p>
                        </div>
                    </div>
                    </Link>
                </div>
                {/* Card 2 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/Feasibility Studies'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Feasibility Studies</h2>
                            <p className="text-sm md:text-base text-justify">Evaluate opportunities and mitigate risks with our in-depth feasibility studies and analyses. Make informed decisions and minimize risk with our comprehensive feasibility studies.</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:justify-center md:items-center md:gap-10 gap-5 md:px-20 px-4  md:mt-4 ">
                {/* Card 1 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/Market Research and Analysis'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Market Research and Analysis</h2>
                            <p className="text-sm md:text-base text-justify">Gain valuable insights and stay ahead of the competition with our market research and analysis services. Uncover opportunities and drive growth with our insightful market research and analysis.</p>
                        </div>
                    </div>
                    </Link>
                </div>
                {/* Card 2 */}
                <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
                <Link to='/Services/Consultancy Services/Digital Transformation'>
                    <div className="p-4 md:p-4 flex items-center bg-white">
                    {/* <RiShieldUserFill className="text-5xl md:text-5xl -mt-10 mr-4 text-blue-500 hover:text-blue-500" /> */}
                        <div>
                            <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]">Digital Transformation</h2>
                            <p className="text-sm md:text-base text-justify">Embrace digital innovations and optimize efficiency with our digital transformation solutions. Stay ahead of the curve and drive digital growth with our tailored digital transformation strategies.</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between text-xs sm:text-base bg-gray-200 md:p-10 mt-8 p-5 gap-6">
            <div className=" bg-white rounded-md md:p-8 ">
          
            <div className="grid lg:grid-cols-2 place-items-center p-5 gap-5">
                <div className='space-y-5'>
                <div className=" text-2xl font-bold ">Our Approach</div>
                <p className="mb-2 text-justify">At Black Eagle Global Venture, transparency is
                the cornerstone of our service approach, guiding every interaction
                and decision. We pride ourselves on our systematic approach, meticulously 
                tailoring solutions to each client's unique needs while maintaining a 
                steadfast commitment to excellence. Our collaborative ethos ensures that 
                clients are actively involved in the process, fostering trust and confidence 
                in our partnership. With a focus on innovation and integrity, we strive to
                exceed expectations, delivering results that propel businesses towards success.
                </p>
                </div>
                <img src={Card3} alt="Explore All" className="lg:w-[500px] w-96" />
            </div>
          <div className="flex-1 py-4 p-5">
          <div className="text-2xl font-bold mb-2">Our Strengths</div>
          <div className=" leading-loose">
            <p className="mb-2 mt-4"><span className='  font-bold'>Forefront of Our Efforts:</span> We lead with dedication and commitment, ensuring that our clients receive the highest standard of service and support.</p>
            <p className="mb-2"><span className='  font-bold'>Diversified Network:</span> Our robust network of investors and lenders spans the globe, offering unparalleled access to tailored capital sources that meet the unique needs of each client.</p>
            <p className="mb-2"><span className='  font-bold'>Extensive Experience:</span> With a wealth of experience across various industries, locations, and transaction types, we provide expert guidance and support at every stage of the capital sourcing and structuring process.</p>
            <p className="mb-2"><span className='  font-bold'>Data-driven Solutions:</span> Anchored in data and insights, our approach enables us to craft customized solutions that drive optimal results and maximize value for our clients.</p>
            <p className="mb-2"><span className='  font-bold'>Global Reach:</span> From local markets to international arenas, our global presence empowers us to navigate complex financial landscapes, delivering impactful solutions that transcend borders.</p>
            <p className="mb-2"><span className='  font-bold'>Strategic Partnerships:</span> We cultivate strategic alliances with key stakeholders, leveraging collaborative efforts to create synergies and unlock new opportunities for growth and success.</p>
          </div>
        </div>
            </div>
        </div>
        <div>
        <div className="text-base px-10 md:text-xl font-bold p-5 sm:p-10 text-center ">
            Expand Your Horizons: Access the Full Range of Capital Solutions, Including Revolving Facilities, Private Equity, and M&A Advisory, to Drive Inorganic Growth and Success.
        </div>
      <section className="flex md:flex-row flex-col px-5 md:px-20 gap-10 w-full items-center justify-center">
        {/* Left Column: Contents */}
        <div className="md:w-1/2">
          <p className="text-gray-700 text-justify text-sm md:text-lg">“Expand Your Horizons: Access the Full Range of Capital Solutions, Including Revolving Facilities, Private Equity, and M&A Advisory, to Drive Inorganic Growth and Success.” At Black Eagle Global Venture, we’re committed to delivering exceptional value and driving tangible results for our clients. Whether you’re looking to optimize your finances, navigate complex challenges, or drive growth through innovation, we’re here to partner with you every step of the way.</p>
        </div>
        {/* Right Column: Title and Contents */}
        <div className="md:w-1/2 flex flex-col gap-2">
          <h2 className="md:text-3xl text-lg font-bold">Capital Advisory Criteria</h2>
          <p className="text-gray-700 text-sm md:text-lg">Our capital advisory services are governed by transparent criteria to ensure optimal outcomes for our clients.</p>
          {/* FAQ */}
          <div className="text-xs md:text-lg">
            <div className="">
              <FaqItem
                question="Private Equity, Private Debt, and Business Financing"
                answer="Target Companies: Consistently growing companies with a minimum annual revenue of $10M and EBITDA of $2M.
                Criteria: Demonstrated growth trajectory and financial stability."/>
              <FaqItem
                question="M&A Advisory"
                answer="Focus: Sell-side opportunities to attract strategic acquirers and financial investors.
                Qualification: Companies with a minimum annual revenue of $10M and EBITDA of $2M are eligible for our M&A support."/>
              <FaqItem
                question="Real Estate Financing"
                answer="Project Focus: Shovel-ready real estate projects with land and necessary approvals/ permits in place.
                Requirements: Sponsor equity percentage is crucial for fundraising success."/>
              <FaqItem
                question="Project Financing"
                answer="Project Scope: Shovel-ready projects with professionally prepared feasibility study reports and detailed project reports.
                Prerequisites: Land, necessary infrastructure, and approvals/ permits in place, along with a specified percentage of sponsor equity for fundraising viability."/>
            </div>
          </div>
        </div>
      </section>
        </div>
   
      <section className="flex w-full md:flex-row  flex-col py-5  px-5 md:px-20 md:gap-10">
        {/* Left Column: Image */}
        <div className="hidden md:block md:w-1/2">
          <img src={Card1} alt="Explore All" className='md:w-96' />
        </div>
        {/* Right Column: Contents */}
        <div className="md:w-1/2 flex flex-col justify-center text-sm md:text-base">
          <div className='flex flex-col gap-3'>
            <div className="font-bold">CONTACT US NOW</div>
            <p className="text-gray-700 text-justify">Ready to explore tailored solutions for your business? Reach out to us for a consultation and discover how our expertise can propel your growth journey forward. Let’s collaborate to unlock new opportunities and drive success together.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services2;
