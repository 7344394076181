import React from 'react';
import Topbar from './Components/Home2/Topbar';
import Navbar from './Components/Home2/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home2/Home';
import AboutUs from './Components/About/AboutUs';
import Footer from './Components/Home2/Footer';
import Contact  from'./Components/Contact Us/Contact';
import Bottombar from './Components/Home2/Bottombar';
import Blog from './Components/Blog/Blog';
import Blog1 from './Components/Blog/Blog1';
import Services1 from './Components/Services/Services1';
import Services2 from './Components/Services/Services2';
import InvestmentBanking from './Components/Capital Advisory/InvestmentBanking';
import Mheader from './Components/Home2/Mheader';
import Ser1 from './Components/Services/ser1';
import Ser2 from './Components/Services/Ser2'
import Ser3 from './Components/Services/Ser3'
import Ser4 from './Components/Services/Ser4'
import Ser5 from './Components/Services/Ser5'
import Ser6 from './Components/Services/Ser6'
import Ser7 from './Components/Services/Ser7'
import Ser8 from './Components/Services/Ser8'
import Ser9 from './Components/Services/Ser9'
import Ser10 from './Components/Services/Ser10'
import Ser11 from './Components/Services/Ser11'
import Ser12 from './Components/Services/Ser12';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className='font-inter'>
      
      <Topbar />
      <Navbar />
      <Mheader  />
      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/about' Component={AboutUs}/>
        <Route path='/blog' Component={Blog} />
        <Route path='/blog/:names' Component={Blog1} />
        <Route path='/contact' Component={Contact} />
        <Route path="/Services/:name" element={<Services1/>}/>
            <Route path="/Services/Consultancy Services" element={<Services2/>}/>
            <Route path="/Services/:divisionname/:division" element={<Ser1 />}/>
            {/* <Route path="/Services/Capital Advisory/Business Finance" element={<Ser2 />}/>
            <Route path="/Services/Capital Advisory/Real Estate Finance" element={<Ser3 />}/>
            <Route path="/Services/Capital Advisory/Project Finance" element={<Ser4 />}/>
            <Route path="/Services/Consultancy Services/Financial Planning and Analysis" element={<Ser5 />}/>
            <Route path="/Services/Consultancy Services/Interim CFO Services" element={<Ser6 />}/>
            <Route path="/Services/Consultancy Services/Financial Modelling and Valuation" element={<Ser7 />}/>
            <Route path="/Services/Consultancy Services/CRTM" element={<Ser8 />}/>
            <Route path="/Services/Consultancy Services/Business Planning" element={<Ser9 />}/>
            <Route path="/Services/Consultancy Services/Feasibility Studies" element={<Ser10 />}/>
            <Route path="/Services/Consultancy Services/Market Research and Analysis" element={<Ser11 />}/>
            <Route path="/Services/Consultancy Services/Digital Transformation" element={<Ser12 />}/> */}
      </Routes>
      <Footer />
      <Bottombar />
    </div>
  );
}

export default App;
