import React, { useEffect, useState } from "react";
import { BsBank } from "react-icons/bs";
import servicebg from "../../Images/service-blackeagle.png";
import { FaUsers } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import { TbPasswordUser } from "react-icons/tb";
import { FaHandHoldingUsd } from "react-icons/fa";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { BsFileEarmarkBarGraphFill } from "react-icons/bs";
import SanityClient from "../Sanity client";
import { GrTechnology } from "react-icons/gr";
import bank from "../../Images/bank3.png";

const Ourservices = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const getdata = async () => {
      await SanityClient.fetch(
        `*[_type=="service"]{
      title,
      subtitle,
      contentarray[]{
        contenttitle,
        contentcontents[]{
          heading,Image{
            asset->{
              url
            }
          }
        }
      }
    }`
      ).then((res) => {
        setdata(res[0]);
        console.log(res[0], "ser");
      });
    };
    getdata();
  }, []);

  return (
    <div className="bg-stone-100">
      <div className="block md:flex xl:flex-row md:flex-col-reverse gap-3 p-5 md:px-10 ">
        <div className="xl:w-[70%]">
          <div className="space-y-3  py-5">
            <h2 className=" text-2xl text-center md:text-left md:text-4xl font-bold">
              {data && data.title}
            </h2>
            <p className="md:text-left text-center md:text-base text-sm">
              {data && data.subtitle}
            </p>
          </div>
          <div className="space-y-3  py-5">
            {data &&
              data.contentarray &&
              data.contentarray.map((catego, index) => (
                <div>
                  <h2 className=" text-lg md:text-lg py-4 text-center lg:text-left  font-semibold ">
                    {catego && catego.contenttitle}
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-center items-center gap-5">
                    {catego &&
                      catego.contentcontents &&
                      catego.contentcontents.map((item, index) => (
                        <div className=" w-full md:w-44 h-40  bg-white bghover shadow-lg shadow-gray-300 rounded-xl gap-3 flex flex-col  justify-center items-center mx-auto">
                          <div className="w-14 h-14 rounded-tl-3xl rounded-br-3xl bg-blue-800 text-white flex justify-center items-center">
                            <img
                              src={item?.Image?.asset?.url}
                              alt=""
                              className="w-[50%] h-[50%] object-cover"
                            ></img>
                          </div>
                          <p className="font-medium text-wrap w-[75%] text-center flex justify-center items-center text-sm">
                            {item && item.heading}
                          </p>
                        </div>
                      ))}
                  </div>

                  {/* <div className="w-full md:w-44 h-40  bg-white bghover shadow-lg shadow-gray-300 rounded-xl gap-3 flex flex-col  justify-center items-center">
                   <div className="w-14 h-14 rounded-tl-3xl rounded-br-3xl bg-blue-800 text-white flex justify-center items-center">
                   <FaHandHoldingUsd size={25}/>
                   </div>
                   <p className="font-medium text-sm">Business Finance</p>
                 </div>
                 <div className="w-full md:w-44 h-40  bg-white bghover shadow-lg shadow-gray-300 rounded-xl gap-3 flex flex-col  justify-center items-center">
                   <div className="w-14 h-14 rounded-tl-3xl rounded-br-3xl bg-blue-800 text-white flex justify-center items-center">
                   <BsFileEarmarkBarGraphFill size={25}/>
                   </div>
                   <p className="font-medium text-sm">Real Estate Finance</p>
                 </div>
                 <div className="w-full md:w-44 h-40  bg-white bghover shadow-lg shadow-gray-300 rounded-xl gap-3 flex flex-col  justify-center items-center">
                   <div className="w-14 h-14 rounded-tl-3xl rounded-br-3xl bg-blue-800 text-white flex justify-center items-center">
                   <MdVerifiedUser size={25}/>
                   </div>
                   <p className="font-medium text-sm">Project Finance</p>
                 </div>
                  */}
                </div>
              ))}
          </div>
        </div>
        <div className="xl:w-[30%] mx-auto">
          <img src={servicebg} alt="" className="xl:w-full" />
        </div>
      </div>
    </div>
  );
};
export default Ourservices;
