import React, { useEffect, useState } from 'react';
import profile1 from '../../Images/t1.png';
import profile5 from '../../Images/t5.png';
import profile3 from '../../Images/t2.png';
import profile4 from '../../Images/t4.png';
import profile2 from '../../Images/t5.png';
import testimonials1 from '../../Images/testimonials1.png';
import testimonials2 from '../../Images/testimonials2.png';
import testimonials3 from '../../Images/testimonials3.png';
import arrow from '../../Images/arrow.png';
import bottomarrow from '../../Images/Polygon 1.png';
import Slider from 'react-slick';
import bg from '../../Images/Rectangle 53.png';
import { useRef } from 'react';
import SanityClient from '../Sanity client';

const Testimonials = () => {
    
//   const [datas,setdatas]=useState([])
//   useEffect(() =>{
//     const fetch = async()=>{
// await SanityClient.fetch(`*[_type == "testimonials"]{
//     Clients[]{
//         clientname,
//         clienttitle,  
//         clienttconent,
//         clientimage{
//             asset->{
//               url
//             }
//           },


//   }
// }
// `).then((result)=>{
//   setdatas(result[0].Clients)
//   console.log(result[0].Clients,"test");
//   setSelected(datas[0])
// })
//     }
// fetch()
//   }
// ,[])
    const sliderRef = useRef();
    const data = [
        {
            name: 'Layla',
            title: 'Exceptional support and guidance throughout our funding journey.',
            description: 'Our experience with Black Eagle Global was nothing short of exceptional. Their team provided invaluable support and guidance at every step, ensuring our success in securing funding for our venture.',
            profile: profile1
        },
        {
            name: 'Mohammad',
            title: 'A game-changer for our startup – highly recommended!',
            description: 'Black Eagle Global has been a game-changer for our startup. Their strategic insights and financial backing propelled our growth to new heights. We highly recommend their services to any entrepreneur seeking investment and mentorship.',
            profile: profile2
        },
        {
            name: 'Aisha',
            title: 'Trusted partners who believed in our vision from day one.',
            description: 'From the initial pitch to closing the deal, Black Eagle Global stood by our side as trusted partners. Their belief in our vision, combined with their expertise and resources, accelerated our journey to success.',
            profile: profile3
        },
        {
            name: 'Rashid',
            title: 'Unparalleled expertise and support tailored to our needs.',
            description: 'We are grateful for the unparalleled expertise and tailored support provided by Black Eagle Global. Their deep understanding of our industry and personalized approach were instrumental in securing funding and navigating complex challenges.',
            profile: profile4
        },
        {
            name: 'Hassan',
            title: 'Transformative investment that fueled our growth trajectory.',
            description: 'The investment from Black Eagle Global was truly transformative for our company. It provided the fuel needed to propel our growth trajectory, enabling us to scale operations, expand our team, and capture new market opportunities.',
            profile: profile5
        },
    ];

    const [selected, setSelected] = useState(data[2]);
    const [selectedIndex, setSelectedIndex] = useState(2);

    const handleBack = () => {
        console.log("back");
        if (selectedIndex === 0) {
            setSelected(data[data.length - 1]);
            setSelectedIndex(() => {
                return data.length - 1;
            });
        } else {
            setSelected(data[selectedIndex - 1]);
            setSelectedIndex(() => {
                return selectedIndex - 1;
            });
        }
        sliderRef.current.slickNext();
    };

    const handleFront = () => {
        if (selectedIndex === data.length - 1) {
            setSelected(data[0]);
            setSelectedIndex(() => {
                return 0;
            });
        } else {
            setSelected(data[selectedIndex + 1]);
            setSelectedIndex(() => {
                return selectedIndex + 1;
            });
        }
        sliderRef.current.slickPrev();
    };

    var settings = {
        infinite: true,
        speed: 500,
        arrow: false,
        slidesToShow: 5, // Adjust the number of slides to show
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>
            {data.length >0 ? <div className='bg-gradient-to-b z-10 test relative xs:p-10 xs:gap-10 flex justify-center items-center flex-col'>
            <section>
                <p className='text-lg xs:text-3xl lg:tex-3xl text-center font-extrabold p-5' style={{ fontFamily: 'Inter, sans-serif' }}>Testimonials</p>
            </section>
            <section className='w-full md:w-[70%] relative flex justify-center items-center flex-col'>
                <div className='absolute sm:block hidden top-0 left-0 -z-10'><img src={testimonials1} className='w-full h-full' alt='bg1' /></div>
                <div className='absolute sm:block hidden top-0 right-0 -z-10'><img src={testimonials2} className='w-full h-full' alt='bg2' /></div>
                <div className='absolute sm:block hidden bottom-12 right-0 -z-10'><img src={testimonials3} className='w-full h-full' alt='bg3' /></div>
                <section className='flex md:p-5 justify-center items-center relative'>
                    <img src={arrow} alt='arrow' className='size-10 cursor-pointer' onClick={handleFront} />
                    <section className='bg-white xs:w-[50%] md:w-[60%] h-fit rounded-xl p-5 text-center text-xs sm:text-xs lg:text-sm flex flex-col gap-2 md:gap-5 justify-between relative' style={{ fontFamily: 'Inter, sans-serif' }}>
                        <p className='font-bold'>{selected.title}</p>
                        <p>{selected.description}</p>
                        <img src={bottomarrow} alt='bottom arrow' className='absolute -bottom-6 z-10 left-1/2 -translate-x-1/2 size-10' />
                    </section>
                    <img src={arrow} alt='arrow' className='size-10 cursor-pointer rotate-180' onClick={handleBack} />
                </section>
                <section className='flex justify-center flex-col items-center w-full h-fit md:pt-2 pt-6 relative pb-20'>
                    <Slider {...settings} className='w-52 sm:w-96 h-fit absolute z-10' ref={sliderRef}>
                        {data && data.map((item, index) => (
                            <img key={index} src={item.profile} alt='' className='rounded-full sm:w-20 sm:h-20 p-2 h' />
                        ))}
                    </Slider>
                    <p className='text-center font-bold' style={{ fontFamily: 'Inter, sans-serif' }}>{selected.name}</p>
                </section>
            </section>
        </div>:<div> </div>}
        
        </div>
    );
};

export default Testimonials;
