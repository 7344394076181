import React from 'react';
import { Link } from 'react-router-dom'; 
import Logo from '../../Images/Blackeagledark (1).png'
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn} from 'react-icons/fa';
import image2 from '../../Images/builderon-img32.jpg';
import { BsChevronRight } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  const Superscript = ({ children }) => {
    return (
      <span style={{ verticalAlign: 'super', fontSize: 'smaller' }}>
        {children}
      </span>
    );
  };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      
  return (
    <>
       <div className="relative -mb-24 md:mt-1 py-24 bg-cover bg-center mx-4 sm:mx-10 md:mx-28">
      <div className="absolute inset-0 bg-primary2 "></div> 
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="max-w-6xl mx-auto px-4">
          <div className="overflow-hidden flex flex-col items-center">
            <h2 className="text-white text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-4 px-4 md:px-0">
              Ready to take your business to the next level?
            </h2>
            <Link to="/contact" className="bg-[#fd5d14] text-white hover:bg-white hover:text-black mt-2 md:mt-6 font-bold py-2 px-6 rounded-lg">Get a Consultation</Link>
          </div>
        </div>
      </div>
    </div>

    <footer className="flex flex-col md:flex-row bg-black text-white md:px-0 lg:px-10 pt-36 pb-4  p-2 ">
      {/* Column 1 */}
      <div className="w-full lg:w-1/3 px-4 mb-4 lg:mb-0 border border-l-0 border-r-1 border-b-0 border-t-0">
        <div className='md:pl-14 mb-2 flex flex-col gap-3 justify-center'>
        <Link to="/">
            <img src={Logo} alt="Explore All" className="" />
          </Link>
          <div>
          <h3 className="text-lg font-bold text-[#fd5d14] mb-2">Head Office: Bahrain</h3>
        <div className="flex pt-2  items-center ">
          
          <BsFillTelephoneFill className="mr-4 text-[#fd5d14]" />
          <Link to="tel:+97365001814" className="text-white text-sm hover:text-[#fd5d14]">+973 65001814</Link>
        </div>

        <div className="flex  mt-2">
          <MdEmail className="mr-4 text-[#fd5d14]" />
          <Link to="mailto:info@blackeagleglobal.com" className="text-white text-sm hover:text-[#fd5d14]">info@blackeagleglobal.com</Link>
        </div>
        <div className="flex mt-2">
          <HiLocationMarker className="mr-4 text-[#fd5d14] text-lg" />
          <Link to="https://maps.app.goo.gl/2KwPS9sjDyW9VWHAA"  target='_blank' className="text-white text-sm hover:text-[#fd5d14]">
            Mahooz Avenue Unit 401 / 0334, <br /> Road 0337 Mahooz,<br /> Kingdom of Bahrain.
          </Link>
        </div>
          </div>
        
      </div>
      </div>

      {/* Column 2 */}
      <div className="w-full lg:w-1/3 px-4 mb-4 lg:mb-0  border border-l-0 border-r-1 border-b-0 border-t-0">
        <h3 className="md:pl-14 lg:mt-[24%] text-lg font-bold  text-[#fd5d14]">Saudi Arabia</h3>
        <div className="flex items-center pt-4">
          <div className="mr-4 md:pl-14  ">
            <Link to="tel:+966500064365" className="text-white  hover:text-[#fd5d14]">
              <BsFillTelephoneFill className='text-[#fd5d14]'/>
            </Link>
          </div>
          <h1 className="text-sm ">
            <Link to="tel:+966500064365" className="hover:text-[#fd5d14]">+966 500064365</Link>
          </h1>
        </div>
        <div className="flex items-center">
          <div className="mr-4 md:pl-14">
            <Link to="tel:+966545025171" className="text-white pt-4 hover:text-[#fd5d14]">
              <BsFillTelephoneFill className='text-[#fd5d14]'/>
            </Link>
          </div>
          <h1 className="text-sm pt-3">
            <Link to="tel:+966545025171" className="hover:text-[#fd5d14]">+966 545025171</Link>
          </h1>
        </div>
        <div className="flex md:pl-14 mt-2">
          <HiLocationMarker className="mr-4 text-xl text-[#fd5d14] " />
          <Link to="https://maps.app.goo.gl/NdVKF7oBJYnwDy5H8"  target='_blank' className="text-white text-sm hover:text-[#fd5d14]">
          <h1 className="text-sm">2<Superscript>nd</Superscript> Industrial city,<br/> Near Al-Qahtani Pipe Company,<br/> Dammam, KSA.</h1>
          </Link>
        </div>
      </div>

      {/* Column 3 */}
      <div className="w-full lg:w-1/3 px-4 mb-4 lg:mb-0">
        <h3 className="md:pl-10 lg:mt-[24%] text-lg font-bold text-[#fd5d14] mb-4">UAE</h3>
        <div className="flex ">
          <div className="mr-4 md:pl-10">
            <Link to="tel:+971585964365" className="text-white  text-sm hover:text-[#fd5d14]">
              <BsFillTelephoneFill className='text-[#fd5d14] text-base'/>
            </Link>
            </div>
          <h1 className="text-sm">
            <Link to="tel:+971585964365" className="hover:text-[#fd5d14]">+971 585964365</Link>
          </h1>
          
        </div>
        <div className="flex  md:pl-10 mt-2">
          <HiLocationMarker className="mr-4 text-lg text-[#fd5d14]" />
          <Link to="https://maps.app.goo.gl/oM4PTFifh19h1efz5" target='_blank' className="text-white text-sm hover:text-[#fd5d14]">
            Office No. 34 Al Kamani Building,<br/> Ajman, UAE.
          </Link>
        </div>
      </div>
    </footer>

    <footer className="bg-black text-white border-t border-white px-4 py-2 ">
      <div className="container mx-auto flex flex-wrap md:px-0 lg:px-[5%]  lg:py-4 p-2 justify-center  lg:justify-between">
        {/* Column 1 */}
        <div className="w-full lg:w-1/4  lg:mb-0">
          {/* <Link to="/">
            <img src={Logo} alt="Explore All" className=" pr-4" />
          </Link> */}
          <h className='text-lg font-bold text-[#fd5d14]'>Black Eagle Global Ventures</h>
          <div>
            <p className='pt-4 text-justify text-sm '>
              We offer unparalleled financial solutions tailored to your needs, backed by our extensive industry expertise and multi location presence.
            </p>
          </div>
          <div className="flex items-center pt-4  space-x-4">
            <a href="https://www.facebook.com/blackeagleglobal" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaFacebook /></a>
            <a href="https://twitter.com/BlackEagleGbl" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaTwitter /></a>
            <a href="https://www.instagram.com/blackeagleglobal/" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaInstagram /></a>
            <a href="https://www.youtube.com/@BlackEagleGlobal" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaYoutube /></a>
            <a href='https://www.linkedin.com/company/black-eagle-global/'  className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]" target='_blank' rel='noopener noreferrer'>
    <FaLinkedinIn />
  </a>
          </div>
        </div>
        {/* Column 2 */}
        <div className="w-full lg:pl-12 lg:w-1/4 mb-4 mt-3 md:mt-0  lg:mb-0">
          <h3 className="md:pl-10 text-lg text-[#fd5d14] font-bold mb-2">Quick links</h3>
          <div className="flex items-center">
            <div className="mr-4 md:pl-10 ">
              <p href="#" className="text-white pt-4 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/" className="text-white   text-sm pt-4 hover:text-[#fd5d14]">Home</Link>
          </div>
          <div className="flex items-center">
            <div className="mr-4 md:pl-10 ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/about" className="text-white  text-sm pt-2 hover:text-[#fd5d14]">About Us</Link>
          </div>
          {/* <div className="flex items-center">
            <div className="mr-4 md:pl-10 ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/Services/Capital Advisory" className="text-white  text-sm pt-2 hover:text-[#fd5d14]">Services</Link>
          </div> */}
          <div className="flex items-center">
            <div className="mr-4 md:pl-10 ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/blog" className="text-white  text-sm pt-2 hover:text-[#fd5d14]">Blog</Link>
          </div>
          <div className="flex items-center">
            <div className="mr-4 md:pl-10 ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/contact" className="text-white  text-sm pt-2 hover:text-[#fd5d14]">Contact Us</Link>
          </div>
        </div>
        {/* Column 3 */}
        <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
          <h3 className=" text-lg font-bold text-[#fd5d14] mb-2">Services</h3>
          <div className="flex items-center pt-2">
            <div className="mr-4">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/Services/capitaladvisory" className="text-white text-sm pt-2 hover:text-[#fd5d14]">Capital Advisory</Link>
          </div>

          <div className="flex items-center">
            <div className="mr-4  ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="" className="text-sm pt-2 hover:text-[#fd5d14]">Mergers and Acquisitions (M&A)</Link>
          </div>
        

          <div className="flex items-center">
            <div className="mr-4  ">
              <p href="#" className="text-white pt-2 hover:text-[#fd5d14]">
                <BsChevronRight className='text-[#fd5d14]'/>
              </p>
            </div>
            <Link to="/Services/ConsultancyService" className="  text-sm pt-2 hover:text-[#fd5d14]">Consultancy Services</Link>
          </div>
        </div>
        {/* Column 4 */}
        <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
          <h3 className=" text-lg font-bold text-[#fd5d14] mb-2">Location</h3>
          <div className="flex pt-4 items-center ">
            <BsFillTelephoneFill className="mr-2 text-[#fd5d14]" />
            <Link to="tel:+97365001814" className="text-white text-sm hover:text-[#fd5d14]">+973 65001814</Link>
          </div>
          <div className="flex  mt-2 ">
            <MdEmail className="mr-2 text-[#fd5d14]" />
            <Link to="mailto:info@blackeagleglobal.com" className="text-white text-sm hover:text-[#fd5d14]">info@blackeagleglobal.com</Link>
          </div>
          <div className="flex mt-2">
            <HiLocationMarker className="mr-2 text-xl text-[#fd5d14] " />
            <Link to="https://maps.app.goo.gl/2KwPS9sjDyW9VWHAA" target='_blank' className="text-white text-sm hover:text-[#fd5d14]">
              Mahooz Avenue Unit 401 / 0334,<br/> Road 0337 Mahooz,<br /> Kingdom of Bahrain.
            </Link>
          </div>
          {/* <div className="flex items-center pt-4  space-x-4">
            <a href="https://www.facebook.com/blackeagleglobal" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaFacebook /></a>
            <a href="https://twitter.com/BlackEagleGbl" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaTwitter /></a>
            <a href="https://www.instagram.com/blackeagleglobal/" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaInstagram /></a>
            <a href="https://www.youtube.com/@BlackEagleGlobal" target="_blank" className="text-black bg-white p-1 rounded-full hover:text-[#fd5d14]"><FaYoutube /></a>
          </div> */}
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
