import React from "react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <section className="px-[5%] md:px-16 font-inter bg-primary  flex justify-between text-md p-1 sm:p-2">
      <section className="flex gap-3 p-1 justify-center items-center text-white">
        <div className="flex gap-2 justify-center items-center">
          <Link to="tel:+97365001814">
            <FaPhoneAlt className="text-xs sm:text-base" />
          </Link>
          <Link to="tel:+97365001814" className="lg:block hidden">
            +973 65001814
          </Link>
          |
        </div>
        <div className="flex gap-2 justify-center items-center">
          <Link to="mailto:info@blackeagleglobal.com">
            <FaEnvelope className="text-xs sm:text-base" />
          </Link>
          <Link
            to="mailto:info@blackeagleglobal.com"
            className="lg:block hidden"
          >
            info@blackeagleglobal.com
          </Link>
          |
        </div>
        <div className="flex gap-2 justify-center items-center">
          <Link to="https://maps.app.goo.gl/2KwPS9sjDyW9VWHAA" target="_blank">
            <FaLocationDot className="text-xs sm:text-base" />
          </Link>
          <Link to="https://maps.app.goo.gl/2KwPS9sjDyW9VWHAA" target="_blank">
            <p className="lg:block hidden">Mahooz, Kingdom of Bahrain</p>
          </Link>
        </div>
      </section>
      <section className="flex gap-5 justify-center items-center text-sm md:text-base text-white">
        <Link
          to="https://www.facebook.com/blackeagleglobal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} className="cursor-pointer" />
        </Link>
        <Link
          to="https://www.instagram.com/blackeagleglobal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} className="cursor-pointer" />
        </Link>
        <Link
          to="https://www.youtube.com/@BlackEagleGlobal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} className="cursor-pointer" />
        </Link>
        <Link
          to="https://twitter.com/BlackEagleGbl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} className="cursor-pointer" />
        </Link>
        <Link
          to="https://www.linkedin.com/company/black-eagle-global/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} className="cursor-pointer" />
        </Link>
      </section>
    </section>
  );
};

export default Topbar;
