import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Logo1 from '../../Images/be3.png';
import Logo2 from '../../Images/be2.png';
import Logo3 from '../../Images/be3.png';
import Logo4 from '../../Images/be4.png';
import line from '../../Images/line.png';
import newf from '../../Images/be1.png';
import dex from '../../Images/be4.png';
import { Link } from 'react-router-dom';
import BG from '../../Images/cl.png';
import  SanityClient  from '../Sanity client';



const AssociateCompanies = () => {

  const [logos, setLogos] = useState([]);
  
  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "OurAssociateCompanies"]{
        link,
        logo{
          asset->{
            id,
            url
          }
        },
       
      }`
    )
    .then((data) => {
      setLogos(data);
      console.log(data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-cover bg-center bg-gray-100 py-5 md:py-10 relative flex flex-col justify-center items-center gap-5" style={{backgroundImage: `url(${BG})`}}>
      <div className='relative h-6 xs:h-8 md:h-12 w-full'>
        <h2 className="text-xl xs:text-lg md:text-3xl font-inter font-bold text-white text-center">Our Associate Companies</h2>
        {/* <img src={line} alt='line' className='bottom-0 hidden sm:block absolute w-full h-full'/> */}
      </div>
      <div className='w-[80%] pt-3'>
      <Slider {...settings}>
        {/* Map over the logos array to render each logo */}
        {logos.map((company, index) => (
          <div key={index}>
            <a href={company?.link} target="_blank" rel="noopener noreferrer">
              <img src={company?.logo?.asset?.url} alt={`Logo ${index + 1}`} className="w-full p-8" />
            </a>
          </div>
        ))}
      </Slider>
      </div>
    </section>
  );
};

export default AssociateCompanies;
