import React, { useState } from 'react';
import vision from '../../Images/vm2.jpg';
import { useEffect } from 'react';
import {useParams} from 'react-router-dom'
import SanityClient from '../Sanity client';
const Blog1 = () => {
        const {names} =useParams()
        const [datas,setdatas]=useState([])
        useEffect(() =>{
                const fetch = async()=>{
            await SanityClient.fetch(`*[_type=="Blog" && blogcontent==${JSON.stringify(names)}]{
              blogcontent,
              blogimage{
                asset->{
                  url
                }
              },subcontent,titlecontents,


            }`).then((result)=>{
             setdatas(result)
              console.log(result);
            })
                }
            fetch()
              }
            ,[])
        
        useEffect(() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
                console.log(names);
              }, []);
            
  return (
    <>
    {datas && datas.map((data,index)=>(<div className="flex  flex-col lg:flex-row justify-center items-center md:py-8 md:px-10 px-6 py-3 lg:px-20 lg:py-10 gap-6 w-full">
      <div className="flex-1 pt-3 md:pt-0">
      <img src={data && data?.blogimage?.asset?.url} alt='' className=''/>
      </div>
      <div className="flex-1 "> {/* Adjust padding between columns */}
        <h2 className="md:text-2xl text-lg font-bold text-left">{data && data.blogcontent}</h2>
        <p className="text-md pt-4 text-justify">{data && data.titlecontents}
        </p>
      </div>
      
    </div>))}
    {/* <div className="flex  flex-col lg:flex-row justify-center md:py-8 md:px-10 px-6 py-3 lg:px-20 lg:py-10 gap-6 w-full">
      <div className="flex-1 pt-3 md:pt-0">
      <img src={vision} alt='' className=''/>
      </div>
      <div className="flex-1 ">
        <h2 className="md:text-2xl text-lg font-bold text-left">The Private Equity as an effective growth capital: An Insider’s Guide</h2>
        <p className="text-sm md:text-base pt-4 text-justify">Private equity investment has become a powerful engine driving growth and innovation for companies
        across developed economies. From North America to Western Europe and Asia, PE firms have fueled
        the rise of countless businesses. However, this growth hasn't always been without controversy. Some
        critics point to job cuts, cost reductions, and limitations on investment as tactics used to achieve shortterm financial gains. While these tactics may benefit shareholders in the immediate term, the longterm impact on the broader economy remains a topic of debate. It's crucial to distinguish between the
        social and financial returns of private equity and understand how PE financing influences economic
        productivity.
        </p>
      </div>
      
    </div> */}
    <div className='flex flex-col md:px-10 lg:px-20 p-6 gap-2'>
        {datas && datas[0] && datas[0].subcontent && datas[0].subcontent.map((item,index)=>(
               <div>
               <p className='font-bold md:text-lg text-md'>{item.heading}</p>
               <p className='text-md text-justify'>{item.headingcontent}</p><br/>
               </div>  
        ))}
        {/* <div>
        <p className='font-bold md:text-base text-sm'>Why Private Equity as an Effective Growth Capital?</p>
        <p className='text-xs md:text-sm text-justify'>Private equity serves as an effective growth capital for established companies, offering several advantages that contribute to their expansion and development:
        </p>
        </div> */}
        {/* <p className='font-bold md:text-base text-sm'>Access to Capital:</p>
        <p className='text-xs md:text-sm text-justify'>Private equity provides companies with access to significant capital resources that
can be utilized for various purposes, such as expanding operations, entering new markets, investing in
research and development, or restructuring the business.</p>
        <p className='font-bold md:text-base text-sm'>Strategic Expertise:</p>
        <p className='text-xs md:text-sm text-justify'>Private equity firms often bring valuable industry expertise, management skills,
and strategic insights to the companies they invest in. This partnership can help businesses enhance
their operations, identify growth opportunities, and navigate challenges more effectively.
</p>
        <p className='font-bold md:text-base text-sm'>Long-Term Focus:</p>
        <p className='text-xs md:text-sm text-justify'>Unlike public markets, where short-term performance is often prioritized, private
equity investors typically have a longer investment horizon. This allows companies to focus on
sustainable growth strategies and make strategic decisions that may take time to yield results.</p>
        <p className='font-bold md:text-base text-sm'>Flexibility:</p>
        <p className='text-xs md:text-sm text-justify'> Private equity investments can be structured in a flexible manner, allowing companies and
investors to tailor financial arrangements based on specific needs and objectives. This flexibility can
facilitate growth initiatives and strategic initiatives that may not be feasible through traditional
financing options.</p>
        <p className='font-bold md:text-base text-sm'>The Private Equity Advantage as Growth Capital:</p>
        <p className='text-xs md:text-sm text-justify'>Private equity stands out as an effective growth capital solution for several reasons:
</p>
        <p className='font-bold md:text-base text-sm'>Tailored Approach:</p>
        <p className='text-xs md:text-sm text-justify'>PE investments are designed to meet a company's specific growth goals. Unlike
traditional loans, PE allows for flexible use of the money, supporting things like market expansion,
product development, and strategic acquisitions.</p>
        <p className='font-bold md:text-base text-sm'>Long-Term Partnership: P</p>
        <p className='text-xs md:text-sm text-justify'> PE investors tend to take a long-term view, aligning their interests with the
company's growth goals. This collaborative approach encourages teamwork, innovation, and strategic
planning, leading to sustained growth over time.</p>
        <p className='font-bold md:text-base text-sm'>Industry Smarts: </p>
        <p className='text-xs md:text-sm text-justify'> PE firms bring valuable industry expertise to the table. They work closely with
management teams to identify growth opportunities, improve operations, and implement effective
growth strategies, leveraging their experience for the best results.</p>
        <p className='font-bold md:text-base text-sm'>Network Power:</p>
        <p className='text-xs md:text-sm text-justify'>PE investors have extensive networks of industry contacts, potential partners, and
resources. This network can open doors to new markets, business opportunities, and strategic
alliances, accelerating growth and market reach.</p>
        <p className='font-bold md:text-base text-sm'>Capital Boost: </p>
        <p className='text-xs md:text-sm text-justify'>PE investments inject significant capital into businesses, allowing them to execute
growth plans quickly and effectively. This capital infusion empowers companies to invest in innovation,
upgrades, hiring talent, and infrastructure development, giving them a competitive edge and making
them stand out in the market.</p>
        <p className='font-bold md:text-base text-sm'>Considering Private Equity for Your Business?</p>
        <p className='text-xs md:text-sm text-justify'>If you're a leader of an established company with ambitious growth plans, private equity financing
might be a strategic option to consider. Carefully evaluate your company's needs and growth goals.
Research and connect with reputable PE firms that specialize in your industry.
</p>
<p className='text-xs md:text-sm text-justify'>Remember, private equity is a partnership. The best PE firms become active collaborators, not just
investors. They'll work alongside you to leverage their expertise, networks, and capital to propel your
business to the next level. By choosing the right PE partner, you can unlock your company's true
potential and achieve sustainable, long-term growth.
</p> */}
    </div>
    </>
  );
};

export default Blog1;
