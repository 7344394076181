import React, { useState } from 'react';
import backgroundImage from '../../Images/blackeagle.png';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SanityClient from '../Sanity client';
const FaqItem = ({ question, answer }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200 font-inter py-4">
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold">{question}</div>
        <button onClick={toggleAnswer} className="focus:outline-none">
          {isOpen ? '-' : '+'}
        </button>
      </div>
      {isOpen && <div className="text-gray-600 mt-2">{answer}</div>}
    </div>
  );
};
function Services1() {
  const { name } = useParams()
  const [data, setdata] = useState([])
  useEffect(() => {

    const getdata = async () => {
      await SanityClient.fetch(`*[_type==${JSON.stringify(name)}]{
  metatitle,
  focuskey,
  metaurl,
  metadescription,
  categorytype,
  title,
  description,
  division[]{
    divisionCategory->{
      Image{
        asset->{
          url
        }
      },
      headline,
subcontentone      
    }
  },
  approach[]{
    heading,
    content,
    image{
      asset->{
        url
      }
    }
  },
  services[]{
    heading,
    subcontent[]{
      heading,
      content
    }
  },
  Horizons[]{
    heading,
    content,
    image,
    seconarray[]{
      heading,
      subheading,
      arrays[]{
        Question,
        ans
      }
    }
  },
  contacts[]{
    heading,
    content,
    image{
      asset->{
        url
      }
    }
  }
}`).then((res) => {
        console.log(res, "cap");
        setdata(res[0])
      })
    }
    getdata()
  }, [name])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className='w-full font-inter'>
      <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full  object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="md:text-4xl text-xl text-center text-white font-bold">{data && data.title}</h1>
          </div>
        </div>
      </div>

      <div className='bg-white p-2 flex  justify-center items-center flex-col '>
        {/* <h4 className='text-lg md:text-2xl font-bold text-black mt-4 mb-0'>{data && data.title}</h4> */}
        <p className="text-base text-justify md:text-center text-black p-4">{data && data.description}</p>
      </div>

      <div className='gap-5 w-full'>
        <div className="grid grid-col md:grid-cols-2 w-full md:justify-center md:items-center md:gap-10 gap-5 md:px-20 px-4 py-4 md:mt-4 ">
          {/* Card 1 */}
          {data && data.division && data.division.map((item, index) => (
            <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
              <Link to={`/Services/${data && data.categorytype}/${item && item.divisionCategory.headline}`}>
                <div className="p-4 md:p-4 flex items-center bg-white">
                  <div>
                    <h2 className="text-lg md:text-xl text-[#2850A2] font-bold  hover:text-[#2850A2]">{item && item.divisionCategory.headline}</h2>
                    <p className="text-sm md:text-base text-justify">{item && item.divisionCategory.subcontentone}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>

        {/* <div className="flex flex-col md:flex-row md:justify-center md:items-center md:gap-10 gap-5 pb-5 md:px-20 px-4 ">
         
          <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
            <Link to="/Services/Capital Advisory/Real Estate Finance">
              <div className="p-4 md:p-4 flex items-center bg-white">
                <div>
                  <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2] ">Real Estate Finance</h2>
                  <p className="text-sm md:text-base text-justify">Navigate the complexities of real estate investments with our specialized financing solutions.Optimize your real estate investments with our expert guidance.</p>
                </div>
              </div>
            </Link>
          </div>

       
          <div className="border rounded-lg overflow-hidden flex-grow md:flex-shrink-0 w-full md:w-1/3 transition duration-300 hover:border-l-4 hover:border-[#2850A2] hover:scale-105">
            <Link to="/Services/Capital Advisory/Project Finance">
              <div className="p-4 md:p-4 flex items-center bg-white ">
                <div>
                  <h2 className="text-lg md:text-xl text-[#2850A2] font-bold mb-2 hover:text-[#2850A2]2">Project Finance</h2>
                  <p className="text-sm md:text-base text-justify ">Structure innovative financial solutions to support your project's development and success. Drive project success with our tailored financial strategies and expertise.</p>
                </div>
              </div>
            </Link>
          </div>
        </div> */}
      </div>
      {data && data.approach &&
        <div className="flex flex-col md:flex-row justify-between text-xs sm:text-base bg-gray-200 md:p-10 mt-8 p-5 gap-6">
          <div className=" bg-white rounded-md md:p-8 ">
            {data && data.approach && data.approach.map((item, index) => (
              <div className="grid lg:grid-cols-2 place-items-center p-5 gap-5">
                <div className='space-y-5'>
                  <div className=" text-2xl font-bold ">{item && item.heading}</div>
                  <p className="mb-2 text-justify">{item && item.content}
                  </p>
                </div>
                <img src={item?.image?.asset?.url} alt="Explore All" className="lg:w-[500px] w-96" />
              </div>
            ))}
            {data && data.services && data.services.map((item, index) => (
              <div className="flex-1 py-4 p-5">
                <div className="text-2xl font-bold mb-2">{item && item.
                  heading}</div>
                <div className=" leading-loose">
                  {item && item.subcontent && item.subcontent.map((listitem, index) => (
                    <p className="mb-2 mt-4"><span className='  font-bold'>{listitem && listitem.heading} </span>{listitem && listitem.content}</p>

                  ))}


                </div>
              </div>
            ))}

          </div>
        </div>
      }



      <div>
        {data && data.Horizons && data.Horizons.map((item, index) => (
          <div>
            <div className="text-base px-10 md:text-xl font-bold p-5 sm:p-10 text-center ">
              {item && item.heading}

            </div>
            <section className="flex md:flex-row flex-col px-5 md:px-20 gap-10 w-full items-center justify-center">
              {/* Left Column: Contents */}
              <div className="md:w-1/2">
                <p className="text-gray-700 text-justify text-sm md:text-lg">{item && item.content}</p>
              </div>
              {/* Right Column: Title and Contents */}
              {item && item.seconarray && item.seconarray.map((point, index) => (
                <div className="md:w-1/2 flex flex-col gap-2">
                  <h2 className="md:text-3xl text-lg font-bold">{point && point.heading}</h2>
                  <p className="text-gray-700 text-sm md:text-lg">{point && point.subheading}</p>
                  {/* FAQ */}
                  {point && point.arrays && point.arrays.map((key, index) => (
                    <div className="text-xs md:text-lg">
                      <div className="">
                        <FaqItem
                          question={key && key.Question}
                          answer={key && key.ans} />

                      </div>
                    </div>
                  ))}

                </div>
              ))}

            </section>
          </div>
        ))}

      </div>
      {data && data.contacts && data.contacts.map((item, index) => (
        <section className="flex w-full md:flex-row  flex-col py-5  px-5 md:px-20 md:gap-10">
          {/* Left Column: Image */}

          <div className="hidden md:block justify-center items-center">
            <img src={item?.image?.asset?.url} alt="Explore All" className='md:w-96' />
          </div>
          {/* Right Column: Contents */}
          <div className="md:w-1/2 flex flex-col justify-center text-sm md:text-base">
            <div className='flex flex-col gap-3'>
              <div className="font-bold">{item && item.heading}</div>
              <p className="text-gray-700 text-lg text-justify">{item && item.content}</p>
            </div>
          </div>
        </section>
      ))}

    </div>
  );
}

export default Services1;
