import React, { useEffect, useState } from 'react';
import backgroundImage from '../../Images/blackeagle.png';
import ABOUT from '../../Images/1.png';
import SanityClient from '../Sanity client';
import { useParams } from 'react-router-dom';
function Ser1() {
  const{division}=useParams()
  const{divisionname}=useParams()
  const[data,setdata]=useState([])
  useEffect(() => {
    console.log(division,divisionname)
    const getdata = async () => {
      await SanityClient.fetch(`
        *[_type==${JSON.stringify(divisionname)} && headline==${JSON.stringify(division)}] {
          headline,
          subcontentone,
          Image {
            asset-> {
              url
            }
          }
        }
      `).then((result) => {
        console.log(result);
        setdata(result[0])
      });
    };
    
    getdata();
  }, [division,divisionname]);

  return (
    <div className='w-full  font-inter '>
      <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-center text-3xl md:text-4xl text-white font-bold">{data && data.headline}</h1>
          </div>
        </div>
      </div>

      <section className="pt-10 px-4 md:px-32"> {/* Adjusted padding for smaller screens */}
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 mb-8 md:mb-0 md:mr-4"> {/* Adjusted width and margin for smaller screens */}
            <img src={data && data?.Image?.asset?.url} alt="Explore All" className="h-auto md:h-60 " /> {/* Adjusted image height */}
          </div>
          <div className="md:w-1/1">
            {/* <div className="text-2xl text-[#2850A3] text-center font-bold md:text-left mb-4">{data && data.headline}</div> */}
            <div className="text-md text-center mb-6 md:text-left">
              <p className='pb-4'>
              {data && data.subcontentone}
              </p>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ser1;
