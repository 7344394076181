import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import SanityClient from '../Sanity client';
import { MdSupervisedUserCircle } from "react-icons/md";
import { MdVerified } from "react-icons/md";

const Cards = () => {

  const [datas, setdatas] = useState([])
  useEffect(() => {
    const fetch = async () => {
      await SanityClient.fetch(`*[_type == "cards"]{
  Services[]{
    cardstitle,  
cardscontents,
link



  }
}
`).then((result) => {
        setdatas(result[0])
        console.log(result[0]);
      })
    }
    fetch()
  }
    , [])

  const [data, setdata] = useState([])
  useEffect(() => {
    const fetchs = async () => {
      await SanityClient.fetch(`*[_type == "about"]{
  aboutsection[]{
  abouttitle,  
aboutcontent,
aboutimage{
  asset->{
    url
  }
},

}
}
`).then((result) => {
        setdata(result[0])
        console.log(result[0]);
      })
    }
    fetchs()
  }
    , [])

  return (
    <>
      <div className=' p-2 mt-6 md:py-10 md:px-20'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 md:gap-10 lg:gap-0 '>
          {datas && datas.Services && datas.Services.map((item, index) => (<div key={index} className='flex hover:text-white mx-auto parent w-[90%] overflow-hidden gap-4 p-6 relative  rounded-lg  h-full'>
            <div className='bg-primary2 w-full h-full absolute overlay top-0 left-0'></div>
            {index === 0 ? <MdSupervisedUserCircle className='text-white icon hidden sm:block bg-primary2 h-16 w-24 rounded-2xl p-1' /> :
              <MdVerified className='text-white icon hidden sm:block bg-primary2 h-16 w-28 rounded-2xl p-1' />}
            <div className='flex flex-col gap-2'>
              <h1 className='sm:text-2xl text-md h1f text-center md:text-left font-semibold'>{item.cardstitle}</h1>
              <p className='text-base pb-2 px-1  text-center md:text-left'>{item.cardscontents}</p>
              <Link to={`/${item && item.link}`} className='flex justify-center md:justify-start'>
                <button className='bg-primary2 buttons text-white px-5 py-2 w-fit rounded-md'>Read More</button>
              </Link>

            </div>
          </div>))}





        </div>
      </div>


      <section className=''>

        {data && data.aboutsection && data.aboutsection.map((item, index) => (<div className='pt-10 px-[5%] md:px-[8%] flex flex-col lg:flex-row gap-10 lg:justify-between justify-center items-center pb-10'>
          <img src={item && item.aboutimage && item?.aboutimage?.asset?.url} alt='' className='md:w-1/2 ' />
          <section className='flex flex-col gap-5 lg:justify-between justify-center lg:items-baseline items-center'>
            <h1 className='font-bold text-xl md:text-4xl md:text-left text-center'>{item.abouttitle}</h1>
            <p className='text-primary font-semibold text-center'>Becoming The Pinnacle Among Peers</p>
            <p className='text-justify leading-relaxed md:text-base text-sm'>{item.aboutcontent}</p>
            <Link to="/about" rel="stylesheet">
              <button class='link-button md:px-6 px-3 py-2  md:py-4 md:text-base text-sm  bg-primary text-white hover:scale-110 transform transition-all rounded-lg'>
                Know More
              </button>
            </Link>
          </section>
        </div>))}

      </section>
    </>
  );
};

export default Cards;
