import React, { useEffect, useState } from 'react';
import { FaRegAddressCard, FaPhone, FaEnvelope, FaHandPointLeft, FaPhoneAlt } from 'react-icons/fa';
import { IoLocation } from 'react-icons/io5';
import ABOUT from '../../Images/black6.png';
import backgroundImage from '../../Images/blackeagle.png';
import { Link } from 'react-router-dom';
import SanityClient from '../Sanity client';

const Contact = () => {
  const [data, setdata] = useState([])

  useEffect(() => {
    const getdata = async () => {
      await SanityClient.fetch(`*[_type=="contactus"]{
  contactusarray[]{
    heading,
    innercontent[]{
      contactcontent{
        asset->{
          url
        }
      },content
    }

  }
 }`).then((res) => {
        setdata(res[0])
        console.log(res[0]);
      })
    }

    getdata()
  }, [])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const formatContent = (content) => {
    if (content.startsWith("+")) {
      return <Link to={`tel:${content}`}>{content}</Link>;
    } else if (content.startsWith("info")) {
      return <Link to={`mailto:${content}`}>{content}</Link>;
      // } else if (content.startsWith("")) {
      //   return <Link href={content}>{content}</>;
    }
    else {
      return content;
    }
  };




  return (
    <section className="flex flex-wrap font-inter items-center">
      <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-4xl text-white font-bold">Contact Us</h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center flex-col md:flex-cols-2 lg:flex-row p-6 md:py-10 md:px-20 gap-6 w-full">
        {/* Column 1: Mobile Address */}
        {data && data.contactusarray && data.contactusarray.map((item, index) => (

          <div className="flex-grow rounded overflow-hidden w-full lg:w-40 shadow-lg">
            <div className="p-6">
              <div className="flex items-center mb-4">
                <div className="font-bold text-xl">{item && item.heading}</div>
              </div>
              {item && item.innercontent.map((list, index) => (
                <div key={index} className="flex items-start mb-4 cursor-pointer">
                  {list.contactcontent.asset.url && (
                    <img src={list.contactcontent.asset.url} className="text-primary2 mr-2 w-5 h-5" size={16} />
                  )}
                  {formatContent(list.content)}
                </div>
              ))}



            </div>
          </div>
        ))}



        {/*  
  <div className="flex-grow rounded overflow-hidden shadow-lg">
  <div className="p-6">
    <div className="flex items-center mb-4">
      <div className="font-bold text-xl">Saudi Arabia</div>
    </div>
    <div className="flex items-center mb-4">
      <FaPhoneAlt className="text-primary2 mr-2" size={16} />
      <a href="tel:+966500064365" className="text-sm">+966 500064365</a>
    </div>
    <div className="flex items-center mb-4">
      <FaPhoneAlt className="text-primary2 mr-2" size={16} />
      <a href="tel:+966545025171" className="text-sm">+966 545025171</a>
    </div>
    <Link to="https://maps.app.goo.gl/NdVKF7oBJYnwDy5H8" target='_blank'>
    <div className="flex  mb-4">
     
        <IoLocation className="text-primary2 mr-2" size={20} />
      
      <div className="text-sm">
        2'nd Industrial city,<br />
        Near Al-Qahtani Pipe Company<br />
        Dammam, KSA
      </div>
     
    </div>
    </Link>
  </div>
</div>


  <div className="flex-grow rounded overflow-hidden shadow-lg">
    <div className="p-6">
      <div className="flex items-center mb-4">
        <div className="font-bold text-xl">UAE</div>
      </div>
      <div className="flex items-center mb-4">
        <FaPhoneAlt className="text-primary2 mr-2" size={16} />
        <a href="tel:+971585964365" className="text-sm">+971 585964365</a>
      </div>
      <Link to="https://maps.app.goo.gl/oM4PTFifh19h1efz5" target='_blank'>
      <div className="flex mb-4">
  
        <IoLocation className="text-primary2 mr-2" size={20} />
        <div className=" text-sm">Office No. 34 Al Kamani <br/>Building, Ajman, UAE.</div>
        
      </div>
      </Link>
      
    </div>
  </div>
</div>*/}
      </div>

      <div className="flex justify-center flex-col lg:flex-row h-fit md:pt-5 md:pb-20 md:px-20 p-6 w-full mb-10">
        <div className=" flex md:w-full lg:w-1/2">
          <img src={ABOUT} alt="Explore All" className="rounded-tl-md rounded-bl-md" />
        </div>

        <div className="w-full md:w-full pb-10 md:pb-0 lg:w-1/2">
          <form className="bg-gray-100 md:p-10 p-4 rounded-lg">
            <h2 className="text-xl font-bold mb-6">GET FREE CONSULTATION</h2>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2"></label>
              <input type="text" id="name" name="name" placeholder="Name" className="w-full p-2 border border-gray-300 rounded-lg" />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-bold mb-2"></label>
              <input type="email" id="email" name="email" placeholder="Email" className="w-full p-2 border border-gray-300 rounded-lg" />
            </div>
            <div className="mb-4">
              <label htmlFor="number" className="block text-gray-700 font-bold mb-2"></label>
              <input type="tel" id="number" name="number" placeholder="Phone" className="w-full p-2 border border-gray-300 rounded-lg" />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-700 font-bold mb-3"></label>
              <textarea id="message" name="message" placeholder="Message" rows="4" className="w-full p-2 border border-gray-300 rounded-lg"></textarea>
            </div>
            <button type="submit" className="bg-[#fd5d14] text-white font-bold py-3 px-6">Send</button>
          </form>
        </div>
      </div>

    </section>
  );
};

export default Contact;
