import React, { useRef, useState } from 'react';
import logo from '../../Images/logo3.png';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import '../../App.css';
import { Link } from 'react-router-dom';
import Mheader from './Mheader';

const Navbar = () => {
  const close1 = useRef(null);
  const close2 = useRef(null);
  const close3 = useRef(null);
  const [subMenu, setSubMenu] = useState(null);
  const [ CA, setCA ] = useState(false);
  const [ CS, setCS ] = useState(false);

  const handleClose = ()=> {
    close1.current = setTimeout(()=> {
      setSubMenu(false)
    }, 600)
  }

  const handleSubMenu = ()=> {
    setSubMenu(true)
    clearTimeout(close1.current);
  }

  const handleCA = ()=> {
    setCA(true)
    clearTimeout(close2.current);
  }
  const handleCAClose = ()=> {
    close2.current = setTimeout(()=> {
      setCA(false)
    }, 600)
  }

  const handleCS = ()=> {
    setCS(true)
    clearTimeout(close3.current);
  }
  const handleCSClose = ()=> {
    close3.current = setTimeout(()=> {
      setCS(false)
    }, 600)
  }

    return (
      <section className='px-[5%] lg:px-16 sticky top-0 sm:flex justify-between items-center font-inter  z-20 bg-white shadow-lg hidden'>
        <Link to='/'>
            <img src={logo} alt='logo' className='h-12 lg:h-24 p-2 md:p-3 cursor-pointer' />
        </Link>
        <section className='flex items-center lg:text-base text-sm font-semibold'>
          <ul className='gap-5 lg:gap-8 hidden md:flex text-md'>
            <li className='n relative w-fit'><Link to="/" >Home</Link></li>
            <li className='n relative w-fit'><Link to="/about" >About Us</Link></li>
            <li className="n relative w-fit" onMouseEnter={()=>{handleSubMenu()}} onMouseLeave={handleClose} >
              <div className="relative">
                <div className="flex flex-row justify-between items-center relative" >
                  <button className={`text-black focus:outline-none flex justify-center items-center gap-1`} >
                    Services <FaChevronDown  className='' size={12}/>
                  </button>
                  {subMenu === true && (
                    <ul className="absolute mt-6 top-full left-0  bg-white shadow-md z-10" onMouseLeave={()=>{handleClose()}}>
                      <li onMouseEnter={handleCA} onMouseLeave={handleCAClose}>
                        <Link to='/Services/capitaladvisory'
                          className={`block px-4 py-2 w-full text-gray-800 text-left text-sm font-semibold hover:text-[#fd5d14] `}                          
                        >
                          Capital Advisory
                        </Link>                     
                        {CA === true && (
                          <ul className="absolute top-0 left-full mt-0 ml-1 bg-white  shadow-md z-10 " >
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Private Equity"
                                className="block text-sm px-4 py-2 text-gray-800 w-52 hover:text-white hover:bg-[#2850A3]"
                               
                              >
                                Private Equity
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Private Debt"
                                className="block text-sm px-4 py-2 text-gray-800 w-52 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                              Private Debt
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Venture Capital"
                                className="block text-sm px-4 py-2 text-gray-800 w-52 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Venture Capital
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Business Finance"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Business Finance
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Real Estate Finance"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                              
                              >
                                Real Estate Finance
                              </Link>
                            </li>
                            
                            <li>
                              <Link
                                to="/Services/capitaladvisorydivision/Project Finance"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                               
                              >
                                Project Finance
                              </Link>
                            </li>
                            {/* Add more sub-submenu items as needed */}
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link
                          to=' '
                          className='block  px-4 py-2 w-64 text-gray-800  text-left text-sm font-semibold hover:text-[#fd5d14]'>
                        Mergers and Acquisitions (M&A)
                        </Link>
                  
                      </li>


                      <li onMouseEnter={handleCS} onMouseLeave={handleCSClose}>
                        <Link
                          to='/Services/ConsultancyService'
                          className={`block  px-4 py-2 w-52 text-gray-800  text-left text-sm font-semibold hover:text-[#fd5d14]`} 
                          
                        >
                          Consultancy Services
                        </Link>
                        {CS === true && (
                          <ul className="absolute top-0 left-full mt-0 ml-1 bg-white shadow-md z-10">
                            <li>
                              <Link
                                to="/Services/Consultdivision/Financial Planning and Analysis"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                               
                              >
                                Financial Planning and Analysis
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Interim CFO Services"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Interim CFO Services
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Financial Modelling and Valuation"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                         
                              >
                                Financial Modelling and Valuation
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/CRTM"
                                className="block text-sm px-4 py-2 w-72 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Corporate Restructuring and Turnaround Management
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Business Planning"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Business Planning
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Feasibility Studies"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Feasibility Studies
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Market Research and Analysis"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                               
                              >
                                Market Research and Analysis
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Accounting Services"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                               
                              >
                                Accounting Services
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/Services/Consultdivision/Tech and Digital Consulting"
                                className="block text-sm px-4 py-2 text-gray-800 hover:text-white hover:bg-[#2850A3]"
                                
                              >
                                Tech and Digital Consulting
                              </Link>
                            </li>
                            
                            {/* Add more sub-submenu items as needed */}
                          </ul>
                        )}
                      </li>


                    </ul>
                  )}
                </div>
              </div>         
            </li>
            <li className='n relative w-fit'><Link to="/blog">Blog</Link></li>
            <li className='n relative w-fit'><Link to="/contact">Contact Us</Link></li>
          </ul>
        </section>
        <div className='justify-center items-center hidden md:flex text-sm'>
          <Link to="/contact">
              <button className='border border-primary2 px-5 py-3 text-white rounded-lg hover:text-primary2 bg-primary2 hover:bg-white hover:scale-110 transition-all shadow-2xl'>Enquire Now</button>
          </Link>
        </div>
      </section>
    );
}

export default Navbar;
