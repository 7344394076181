import React, { useState } from 'react';
import vision from '../../Images/vm2.jpg';
import vision2 from '../../Images/vm1.jpg';
import vision3 from '../../Images/bannerbg.jpeg';
import backgroundImage from '../../Images/blackeagle.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import SanityClient from '../Sanity client';

const Blog = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  const [datas,setdatas]=useState([])
  useEffect(() =>{
    const fetch = async()=>{
await SanityClient.fetch(`*[_type == "Blogseo"]{
  blogdetails[]->{
    metatitle,
    metadata,
    mettaurl,
    focousekeywords,
    blogcontent,
    blogimage{
      asset->{
        url
      }
    }
  }
}`).then((result)=>{
  setdatas(result[0])
  console.log(result[0]);
})
    }
fetch()
  }
,[])

  return (
    <div className='font-inter'>
      <div className='bg-black'>
        <div className="relative">
          <img src={backgroundImage} className="w-full h-40 md:h-full object-cover opacity-40" alt="Background" />
          <div className="absolute inset-0 flex justify-center items-center">
            <h1 className="text-4xl text-white font-bold">Blog</h1>
          </div>
        </div>
      </div>
  
      <div className="grid grid-row md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center px-4 py-10 md:py-16 md:px-16 lg:pt-10 lg:pb-28">
        {datas && datas.blogdetails && datas.blogdetails.map((items,index)=>(
          <div className="text-center">
          <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center h-full transition-transform transform hover:scale-105">
            <img src={items && items?.blogimage?.asset?.url} alt='' className='w-full h-48 object-cover rounded-md'/>
            <h2 className="text-lg font-semibold py-4">{items && items.blogcontent}</h2>
            <Link to={`${items && items.blogcontent}`} className="text-blue-600 hover:underline">Read More...</Link>
          </div>
        </div>
        ))}
        
        {/* <div className="text-center">
          <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center h-full transition-transform transform hover:scale-105">
            <img src={vision2} alt='' className='w-full h-48 object-cover rounded-md'/>
            <h2 className="text-lg font-semibold py-4">Leveraging Private Debt for Business Growth: Opportunities and benefits for middle market companies</h2>
            <Link to="/blog" className="text-blue-600 hover:underline">Read More...</Link>
          </div>
        </div>
        <div className="text-center">
          <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center h-full transition-transform transform hover:scale-105">
            <img src={vision3} alt='' className='w-full h-48 object-cover rounded-md'/>
            <h2 className="text-lg font-semibold py-4">Maximizing business success through Financial Planning and Analysis (FP&A)</h2>
            <Link to="/blog" className="text-blue-600 hover:underline">Read More...</Link>
          </div>
        </div>
        <div className="text-center">
          <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center h-full transition-transform transform hover:scale-105">
            <img src={vision2} alt='' className='w-full h-48 object-cover rounded-md'/>
            <h2 className="text-lg font-semibold py-4">Driving Business Success<br/> through Financial Planning and Analysis</h2>
            <Link to="/blog" className="text-blue-600 hover:underline">Read More...</Link>
          </div>
        </div>
        <div className="text-center">
          <div className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center h-full transition-transform transform hover:scale-105">
            <img src={vision3} alt='' className='w-full h-48 object-cover rounded-md'/>
            <h2 className="text-lg font-semibold py-4">Outsources accounting services and the benefits of Professional Accounting to the businesses</h2>
            <Link to="/blog" className="text-blue-600 hover:underline">Read More...</Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Blog;
